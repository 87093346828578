<template>
    <div class="goods-container">
        <div class="script-box">
            <LiveScript></LiveScript>
        </div>
        <div class="goods-table">
            <el-table :data="goodList" class="customTable"
                style="width: 100%;flex: 1;border: #f0efef 1px solid;border-radius: 4px;" height="100%">
                <el-table-column prop="image_url" label="商品信息" align="left">
                    <template slot-scope="scope">
                        <div class="goodsInfo" @click="gotoDetail(scope.row)">
                            <div style="width: 58px">
                                <img :src="scope.row.src.url" alt="" width="58px" height="58px">
                            </div>
                            <span class="text-overflow-2">{{ scope.row.goods_name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="daily_price" label="日常价" align="center">
                    <template slot-scope="scope">
                        <span>￥{{ Number(scope.row.daily_price).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="live_price" label="直播间价格" align="center">
                    <template slot-scope="scope">
                        <span>￥{{ Number(scope.row.live_price).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="editShop(scope.row, 'title', 1)">编辑标题</el-link>
                        <el-link style="margin-left:10px" type="primary" :underline="false"
                            @click="editShop(scope.row, 'price', 2)">编辑商品价格</el-link>
                        <el-link style="margin-left:10px" type="primary" :underline="false"
                            @click="blankDetail(scope.row)">商品数据</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :title="title === 'price' ? '编辑商品价格' : '编辑标题'" :close-on-click-modal="false" :visible.sync="formVisible"
            width="40%">
            <template v-if="editData">
                <el-form :model="editData">
                    <el-form-item v-if="title === 'price'" label="" label-width="25px">
                        <span style="font-size:16px">商品标题:{{ editData.goods_name }}</span>
                    </el-form-item>
                    <el-form-item v-if="title === 'title'" label="商品标题:">
                        <el-input style="width:360px" v-model="editData.goods_name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item v-if="title === 'price'" label="日常价:" label-width="80px">
                        <el-input-number :controls="false" :precision="2" style="width:360px" v-model="editData.daily_price"
                            autocomplete="off"></el-input-number>
                        <span style="margin-left:5px">元</span>
                    </el-form-item>
                    <el-form-item v-if="title === 'price'" label="直播间价:" label-width="80px">
                        <el-input-number :controls="false" :precision="2" style="width:360px" v-model="editData.live_price"
                            autocomplete="off"></el-input-number>
                        <span style="margin-left:5px">元</span>
                    </el-form-item>
                </el-form>
            </template>
            <div slot="footer" class="dialog-footer">
                <el-button @click="formVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getLiveGoodsList, saveLiveGoodsList } from "@/utils/apis";
import { mapActions } from 'vuex';
import LiveScript from "../../../components/LiveScript.vue"
export default {
    name: "GoodsManagement",
    components: {
        LiveScript
    },
    data() {
        return {
            goodList: [],
            formVisible: false,
            pages: {
                currentPageNum: 1,
                eachPageNum: 5,
                total: 0
            },
            title: '',
            editData: void 0,
        }
    },

    methods: {
        ...mapActions([
            'setLiveGoodID',
            'setLiveGoods'
        ]),
        getGoodsList() {
            let obj = {
                paging: 0,
            }
            getLiveGoodsList(obj).then((res) => {
                this.goodList = res.data.list
                this.pages.total = res.data.total
                localStorage.setItem('goodsList', JSON.stringify(this.goodList))
                this.setLiveGoods(this.goodList);
            })
        },
        editShop(obj, tag, flag) {
            localStorage.setItem('live_goods_id', obj.id)
            this.title = JSON.parse(JSON.stringify(tag))
            this.editData = JSON.parse(JSON.stringify(obj))
            console.log(this.editData)
            this.editData.type = flag
            this.formVisible = true
        },
        saveEdit() {
            if (this.title == 'title' && this.editData.goods_name == '') {
                this.$message.warning("请填写标题")
                return
            }
            if (this.editData.goods_name.length < 2 || this.editData.goods_name.length > 50) {
                this.$message.warning("标题长度为2到50个字符")
                return
            }
            if ([undefined, null, ''].includes(this.editData.daily_price) || [undefined, null, ''].includes(this.editData.live_price)) {
                this.$message.warning("请填写价格")
                return
            }
            saveLiveGoodsList(this.editData).then((res) => {
                if (res.code === 200) {
                    this.setLiveGoodID(void 0);
                    this.setLiveGoodID(this.editData.id);
                    this.$message.success('编辑内容成功')
                    this.formVisible = false;
                    this.getGoodsList()
                }
            })
        },
        gotoDetail(row) {
            let router = this.$router.resolve({
                path: "/student/liveStreamingSales/goodsDetail",
                query: {
                    id: row.goods_id,
                    name: row.goods_name,
                    price: row.daily_price,
                    livePrice: row.live_price,
                }
            })
            window.open(router.href, '_blank')
        },
        blankDetail(row) {
            let router = this.$router.resolve({
                path: "/gooditemDetail",
                query: {
                    id: row.id,
                    name: row.goods_name,
                    price: row.daily_price,
                    livePrice: row.live_price,
                    src: row.src.url
                }
            })
            window.open(router.href, '_blank')
        },
        currentChange(val) {
            this.pages.currentPageNum = val;
            this.getGoodsList()
        }
    },
    mounted() {
        this.getGoodsList()
    },
}
</script>

<style scoped lang="scss">
::v-deep .customTable td:first-child>.cell {
    padding-left: 25px;
}

::v-deep .customTable th:first-child>.cell {
    padding-left: 25px;
}

/deep/.pages-center {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.goods-container {
    height: calc(100vh - 140px);
    display: flex;

    .script-box {
        width: 600px;
        height: 100%;
        margin-right: 20px;
    }

    .goods-table {
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: hidden;
    }
}

.goodsInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;

    span {
        margin-left: 10px;
    }
}
</style>